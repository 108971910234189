import React from 'react'

function SkelAnimation() {
  return (
    <div className="shimmer-wrapper">
        <div className="shimmer">
        </div>
    </div>
  )
}

export default SkelAnimation;