import './Features.css';
import Card from '../../../UI/card/Card';
import SkelFeatureCard from '../../../UI/Skeletons/SkelFeatureCard';
import UF from '../../../../assets/UF.svg';
import ST from '../../../../assets/ST.svg';
import OS from '../../../../assets/OS.svg';
function Features(props) {


    const CreateCards=(content)=>{
        var image='';
        const final= content.map(data=>(
            <div className='mt-2 col-lg-4 col-sm-6 col-12 d-flex justify-content-center align-items-stretch'>
            <Card style={{minHeight:'300px',maxHeight:'100%',padding:'10%',borderRadius:'16px'}} title={data.title} text={data.content} image={data.image}/>
     
            </div>
            ));
      return final
       }

       const CreateSkelCards = () => {
        var features = 'features';
        const final = [1, 2, 3].map(n => (
            <div className='mt-2 col-lg-4 col-sm-6 col-12 d-flex justify-content-center align-items-stretch'>
                <SkelFeatureCard style={{ minHeight: '300px', maxHeight: '100%', padding: '10%', borderRadius: '16px' }} cardtype={features} />
            </div>
        ));
        return final
    }

    return ( 
        <>
          <div className='text-center mt-4' >
       <span className='headtext'>Why GemPrf?</span>
       </div>
       {/* <div className='container mt-5 card-group'>
    <div className='row'>
    {CreateCards(props.content)}
        </div>
        </div> */}
        {props.content.length !== 0 ?
                <div className='container mt-5 card-group'>
                    <div className='row'>
                        {CreateCards(props.content)}
                    </div>
                </div>
                : <div className='container mt-5'>
                    <div className='row'>
                        {CreateSkelCards()}
                    </div>
                </div>
            }
        </>
     );
}

export default Features;