import './HeroSection.css';
import GemPrfBanner from '../../../../assets/GemPrfBanner.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from 'glamor';


toast.configure()
function HeroSection() {

  const copyText = () => {

    var copyText = document.getElementById("text").innerText;
    document.addEventListener("copy", (e) => {
      e.clipboardData.setData('text/plain', copyText);
      e.preventDefault();
      document.removeEventListener("copy", null);

    });
    document.execCommand("copy");

    toast.info('Copied!', {

      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark"
    })
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <img src={GemPrfBanner} alt="button" />
        <div className="px-4 py-2 heroText">
          <span id="text"> pip install gemprf</span>
          <FontAwesomeIcon style={{ cursor: 'pointer' }} className="ms-3" icon={faCopy} onClick={copyText} data-toggle="tooltip" title="Click to Copy!" />
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default HeroSection;