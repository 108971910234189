import Navbar from '../../UI/navbar/Navbar';
import './GemPrf.css';
import Footer from '../../UI/footer/Footer';
import HeroSection from '../../Pages/GemPrfPage/heroSection/HeroSection';
import Features from '../../Pages/GemPrfPage/features/Features';
import Advantages from '../../Pages/GemPrfPage/advantages/Advantages';
import axios from 'axios';
import { useEffect, useState } from 'react';

function GemPrf() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [window.location.hash]);
   const [featureContent,setFeatureContent] =useState([]);
   const [advantages,setAdvantages] = useState([]);
    useEffect(()=>{
axios.get('https://apis.gemecosystem.com/intro?gem=GemPerf',{

}).then(data=>{
    console.log(data.data.data);
    setFeatureContent(data.data.data.data.why);
    setAdvantages(data.data.data.data.use)
});
    },[]);

    return ( 
        <>
        <Navbar></Navbar>
      <HeroSection></HeroSection>
      <div className="chooseus">
      <Features content={featureContent}></Features>
      </div>
      <Advantages content={advantages}></Advantages>
<Footer></Footer>
        </>
    );
}

export default GemPrf;