import './Footer.css';
import LOGO from '../../../assets/Footer.svg';
import logo from '../../../assets/GemPrfLogo.svg';
import footertagline from '../../../assets/footertagline.svg';
import fb_icon from '../../../assets/icon_fb.svg';
import twitter_icon from '../../../assets/icon_twitter.svg';
import insta_icon from '../../../assets/icon_insta.svg';
import linkedin_icon from '../../../assets/icon_linkedin.svg';
import { Link } from 'react-router-dom';

function Footer() {
    return ( <>
     <div className="footer">
 
     <div className="container-fluid pt-4 px-xl-5">
<div className="row gx-5">
    <div className="col-lg-3 col-sm-6 col-12">
        <div className="footdiv">
            <img src={logo} className="mx-auto d-block pt-3 footlogo"/>
            <img src={footertagline} className="mx-auto d-block pt-4" style={{width: '200px'}}/>
            <div className="row mb-2">
            <div className="d-flex justify-content-center text-center">
                    <a href="https://www.facebook.com/gemecosystem" className='icon_linksfoot' target="_blank"><img className="icon" src={fb_icon}/></a>
                    <a href="https://twitter.com/gemecosystem" className='icon_linksfoot' target="_blank"><img className="icon" src={twitter_icon}/></a>
                    <a href="https://www.instagram.com/gemecosystem/" className='icon_linksfoot' target="_blank"><img className="icon" src={insta_icon}/></a>
                    <a href="https://www.linkedin.com/company/gemecosystem/" className='icon_linksfoot' target="_blank"><img className="icon" src={linkedin_icon}/></a>
                </div>
            </div>
        </div>
{/* <div className="heading-text">

<img src={LOGO} alt="LOGO" />
</div>

<div className="sub-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique id scelerisque libero</div> */}
    </div>
    <div className="col-lg-3 col-sm-6 col-12">
<div className="heading-text2">
    Feature
</div>
<ul className="sub-text list">
<li><Link to="/jewel">Jewel</Link></li>
    <li><a href="https://gempyp.gemecosystem.com/" target="_blank">GemPyp</a></li>
    <li><Link to="/gemprf">GemPrf</Link></li>
    <li><a href="https://gemjar.gemecosystem.com/" target="_blank">GemJar</a></li>
    
</ul>
</div>

<div className="col-lg-2 col-sm-6 col-12">
<div className="heading-text2">
    Company
    </div>
    <ul className="sub-text list">
    <li >About Us</li>
    <li>Our works</li>
    <li>Pricing</li>
    <li>Terms of use</li>
    <li>FAQs</li>
    
</ul>
</div>

<div className="col-lg-4 col-sm-6 col-12">
<div className="heading-text2">
Send Updates
    </div>
    <div className="input-group mt-2">
  <input type="text" className="form-control" placeholder="Enter your email address" aria-label="Enter your email address" aria-describedby="button-addon2" />
  <button className="btn btn-primary" style={{backgroundColor:'#373163',border:'1px solid #373163'}} type="button" id="button-addon2">Subscribe</button>
</div>
</div>
</div>

  </div>
  </div>
    </> );
}

export default Footer;