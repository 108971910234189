import Card from '../../../UI/card/Card';
import SkelFeatureCard from '../../../UI/Skeletons/SkelFeatureCard';
function Advantages(props) {

    const CreateCards = (content) => {
        const final = content.map(data => (

            <div className='mt-2 mb-4 col-lg-4 col-sm-6 col-12 d-flex justify-content-center align-items-stretch'>
                <Card style={{ minHeight: '300px', maxHeight: '100%', padding: '10%', borderRadius: '16px' }} title={data.title} text={data.content} />

            </div>
        ));
        return final;
    }
    
    const CreateSkelCards = () => {
        var advantages = 'advantages';
        const final = [1, 2, 3, 4, 5, 6].map(n => (
            <div className='mt-5 col-lg-4 col-sm-6 col-12 d-flex justify-content-center align-items-stretch' key={n}>
                <SkelFeatureCard style={{ minHeight: '300px', maxHeight: '100%', padding: '10%', borderRadius: '16px' }} cardtype={advantages} />
            </div>
        ));
        return final;
    }

    return (
        <>
            <div className="mb-5">
                <div className='text-center mt-4' >
                    <span className='headtext'>Advantages</span>
                </div>

                <div className='container mt-5'>
                    {props.content.length !== 0 ?
                        <div className='row'>
                            {CreateCards(props.content)}
                        </div>
                        :
                        <div className='row'>
                            {CreateSkelCards()}
                        </div>
                    }
                </div>

            </div>
        </>
    );
}

export default Advantages;