import './Navbar.css';
import logo from '../../../assets/GemPrfLogo.svg';

import { Link } from 'react-router-dom';
function Navbar(props) {


  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light text-center">

        {/*tab mode logo */}
        <Link className="ms-4 d-lg-none d-none d-sm-block" to="/">
          <img src={logo} alt="button" />
        </Link>
        {/* mobile view logo */}
        <Link className="d-sm-none" to="/" style={{ margin: 'auto auto' }}>
          <img src={logo} alt="button" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ marginLeft: '34px', marginRight: '34px' }}>
          <ul className="navbar-nav nav-fill w-100">
            {/* <div className="row"> */}
            <li className="nav-item col-md d-none d-lg-block">
              <Link className="nav-link" to="/">
                <img src={logo} alt="button" />
              </Link>
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="#">GETTING STARTED  </a> */}
              <Link className="nav-link" to="/getting-started">GETTING STARTED </Link>
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="#">USE CASES  </a> */}
              <Link className="nav-link" to="/usecases">USE CASES </Link>

            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                CUSTOM TESTCASES
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#">Action</a>
                <a className="dropdown-item" href="#">Another action</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">Something else here</a>
              </div>
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="#">CODE</a> */}
              <Link className="nav-link" to="/code">CODE </Link>
            </li>
            {/* </div> */}
          </ul>
        </div>
      </nav>
    </>);
}

export default Navbar;