import React from 'react'
import SkeletonElement from './SkeletonElement';
import SkelAnimation from './SkelAnimation';
import './Skeletons.css'

function SkelFeatureCard(props) {
    console.log(props.cardtype)
    return (
        <>
            <div className="card shadow customskelcard w-100" style={props.style}>
                <div className="card-body text-center">
                    {props.cardtype!=='advantages' ? 
                    <div className="d-flex justify-content-center"><SkeletonElement type="avatar"/></div>
                    : null}

                        <div className="d-flex justify-content-center"><SkeletonElement type="title"/></div>
                        <SkeletonElement type="text"/>
                        <SkeletonElement type="text"/>
                        <SkeletonElement type="text"/>
                        <SkeletonElement type="text"/>
                        <SkeletonElement type="text"/>
                        <SkeletonElement type="text"/>
                        <SkeletonElement type="text"/>

                </div>
                <SkelAnimation/>
            </div>
        </>
    )
}

export default SkelFeatureCard;