import './App.css';
import GemPrf from './components/Layouts/gemprf/GemPrf';
import {HashRouter as Router
  ,Routes
  ,Route} from 'react-router-dom';
  import ComingSoon from './components/Layouts/ComingSoon/ComingSoon';
import Error404 from './components/Layouts/Error 404/Error404';

function App() {
  return (
<div>
<Router>
        <Routes>
        <Route exact path="/" element={<GemPrf />} />
        <Route path="/getting-started" element={<ComingSoon />} />
        <Route path="/usecases" element={<ComingSoon />} />
        <Route path="/code" element={<ComingSoon />} />
        <Route path="/jewel" element={<ComingSoon />} />
        <Route path="/gemprf" element={<ComingSoon />} />
        <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
</div>
  );
}

export default App;
