import './Card.css';
import {Link} from 'react-router-dom';

// import UF from '../../assets/UF.svg';
// import ST from '../../assets/ST.svg';
// import OS from '../../assets/OS.svg';
import { useEffect, useState } from 'react';
import userEvent from '@testing-library/user-event';

//button-onClick
//link-href
//navigation-route 

function Card(props) {
  
  const [imageSrc,setImageSrc] =useState('');
  useEffect(()=>{
      setImageSrc(props.image);
  },[])
  
  console.log(imageSrc);
    return ( <>
    <div className="card customcard" style={props.style}>
        <div className="card-body text-center">
            {
                imageSrc ?
            <div className="mt-2">

<img src={require('../../../assets/'+imageSrc)} alt="icon" />
            </div> :null
}
           { 
           props.title?<div className="title-text mt-3">
                {props.title}
            </div>:null
            }

            {
             props.text?<div className="card-text mt-2">
           {props.text}
            </div>
         :null
         }
             { 
             props.navigation ?<div className="mt-3"> <Link  to={props.route!==undefined ? props.route:'/'}>
<img src={props.btnicon} className="btn" alt="button" /> 
</Link> </div> :
null
}

{
    props.button ? <button onClick={props.onClick?()=>props.onClick():()=>console.warn('No onClick Function Passed')} className="btn btn-primary mt-3" style={{backgroundColor:'#3D64D3'}}>Read More</button>
    :null
}
{props.link? <div className="mt-3">
        <a href={props.href?props.href:"/" } target="_blank" >Read More</a>
    </div>:null}
           
        </div>
    </div>
    </> );
}

export default Card;